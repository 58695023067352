import { decryptWithAES } from "../../utils/funtionCommon";

export default interface IThankyouParameters {
  getStatus(): string;

}

export default class ThankyouParameters implements IThankyouParameters {
  private status: string;
  private sessionID: string;
  private payment_intent: string;

  constructor(location: string) {
    const parameters = new URLSearchParams(location);
    
    this.status = parameters.get("redirect_status");
    this.sessionID = parameters.get("sessionID");
    this.payment_intent = parameters.get("payment_intent");
  }

  public getStatus(): string {
    return this.status ? this.status : 'pending';
  }

  public getSessionID(): string {
    if (this.sessionID) {
      const dataConvert = decryptWithAES(this.sessionID.replace(/ /g, '+'), 'thankyou');
      return this.sessionID ? dataConvert ? JSON.parse(dataConvert) : null : null;
    }
  }

  public getPaymentID(): string {
    return this.payment_intent ? this.payment_intent : '';
  }
}
